footer {
  background-color: var(--black);
  min-height: 5rem;
  text-align: center;
  color: var(--white);
  padding: 1rem;

  display: flex;
  justify-content: center;

  a {
    color: var(--secondary);
  }
}
