@import 'todoList';
@import 'calculator';

.projects {
  display: grid;
}

canvas {
  margin: 3rem 0;
}
