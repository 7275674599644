.todoList {

  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-top: 1rem;
    display: flex;
    border-bottom: 1px solid black;
    justify-content: space-between;
  }

  .checked {
    text-decoration: line-through;
  }

}
