// Navigation sidebar

.navigation {

  [data-nav-list="visible"] {
    transform: translateX(0);
  }

  [data-nav-list="hidden"] {
    transform: translateX(-12rem);
  }

  ul {
    transition: transform .5s ease-in-out;
    position: fixed;
    height: 100%;
    width: 12rem;
    background-color: var(--black);
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
    margin: .5rem;

    :hover:not(.selected) {
      background-color: rgba(40,40,40,1);
    }
  }

  a {
    color: var(--gray);
    text-decoration: none;
    padding: 1rem;
    display: block;
    margin: 0;
  }

  .selected {
      color: white;
      background-color: var(--dark-gray);
  }
}
