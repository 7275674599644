@import "normalize";

:root {
  // Colors
  --primary: hsla(240, 90%, 50%, 1);
  --primary-light: hsla(240, 90%, 80%, 1);
  --primary-dark: hsla(240, 90%, 40%, 1);

  --secondary: hsla(340, 90%, 60%, 1);

  // Whites
  --white: hsla(0, 0%, 97%, 1);
  --white-transparant: hsla(0, 0%, 97%, 0.9);

  // Grays
  --light-gray: hsla(0, 0%, 80%, 1);
  --gray: hsla(0, 0%, 60%, 1);
  --dark-gray: hsla(0%, 0%, 30%, 1);

  // Blacks
  --black: hsla(0, 0%, 10%, 1);

}

// spacing
$spacing-thin: 1rem;
$spacing-small: 2rem;
$spacing: 3rem;
$spacing-big: 5rem;
$spacing-huge: 10rem;

// Sass variables that we can use in media queries
$break-small: 30rem;
$break-medium: 50rem;
$break-large: 1200px;

// standard units
$viewport: 70rem;

// Sass variables that we can use in media queries
$thin: 15rem;
$narrow: 30rem;
$slender: 50rem;
$wide: 1200px;

$tall: 25rem;

// keyframes effects
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
