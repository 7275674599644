.gallery {

  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin-bottom: 2rem;
  }

}

figure {
  margin: 0;
}

figcaption {
  text-align: center;
  font-style: oblique;
}
