// Form styles and general :focus accessebility styles


form {

  :focus {
    outline: 2px;
    outline-style: solid;
    outline-color: var(--primary);
    box-shadow: none;
  }


  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  button,
  input,
  select,
  label,
  textarea {
    margin-top: 1rem;
    border: none;
  }

  select {
    height: 2.5rem;
    appearance: none; // removes default arrow
    border-radius: 0;
    padding: .5rem;
    background-image: url('../images/triangle.svg'); // add arrow
    background-repeat: no-repeat;
    background-position-x: calc(100% - .5rem);
    background-position-y: center;
    background-color: white;

    box-sizing: border-box;
  }

  // styles for Firefox
  input[type="range"] {
    width: 100%;
    padding: 0;
  }

  input,
  textarea {
    padding: 0.5rem;
  }

  textarea {
    height: 10rem;
  }

  // form validation
  .was-validated {
    .form-control {
      &:valid~.invalid-feedback {
        display: none;
      }
    }
  }
}
