// Typography, base styles

body {
  @import url('https://fonts.googleapis.com/css?family=Oswald|Raleway&display=swap');
  font-family: 'Raleway', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.9;
  font-display: swap;
  background-color: var(--white);
  min-height: 100vh;
}

main {
  min-height: 100vh;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Oswald', 'Helvetica', 'Arial', sans-serif;
  margin-bottom: 0;
}

h1 {
  font-size: 2.5em;
  line-height: 1.4;
}

h2 {
  font-size: 1.5em;
  line-height: 1.5;
}

.content-container {
  max-width: $slender;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (max-width: $slender + 2rem) {
    margin: 0 1rem;
  }
}

.content-container:last-child {
  padding-bottom: 2rem;
}

// Helper classes
.text-center {
  text-align: center;
}

// code blocks
pre {
  padding: 1rem;
  background-color: var(--light-gray);
  overflow: scroll;
}
